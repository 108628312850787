import axios from "axios";
import { SERVER_API } from "../constants/urls";

export default axios.create({
  baseURL: SERVER_API,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
