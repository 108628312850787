import React, { useState } from "react";
import style from "./FormInput.module.css";
import { IFormInputProps } from "./interfaces";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function (props: IFormInputProps) {
  const [visible, setVisible] = useState<boolean>(false);

  const passwordVisibilityIcon = () => {
    {
      return (
        props.type === "password" && (
          <div
            className={style.passwordVisibilityIcon}
            onClick={(_) => setVisible((visibility) => !visibility)}
          >
            {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </div>
        )
      );
    }
  };

  const type =
    props.type != "password" ? props.type : visible ? "text" : "password";

  return (
    <div className={style.container}>
      <label className={style.label}>{props.label}</label>
      <input
        className={style.input}
        type={type}
        pattern={props.pattern}
        onChange={props.onChange}
        onBlur={(e) => {
          props.onBlur(e);
          e.target.setAttribute("focused", "true");
        }}
        name={props.name}
        required={props.required}
      />
      {passwordVisibilityIcon()}
      <span className={style.span}>{props.error}</span>
    </div>
  );
}
