import React, {
  useRef,
  KeyboardEvent,
  ChangeEvent,
  ChangeEventHandler,
} from "react";
import style from "./style.module.css";
import SendIcon from "@mui/icons-material/Send";

interface IProps {
  onSend: (msg: string) => void;
}

const InputMessage = (props: IProps) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const send = () => {
    if (!inputRef.current) {
      return;
    }
    const message: string | undefined = inputRef.current?.value;
    if (!message) return;
    props.onSend(message);
    inputRef.current.value = "";
    inputRef.current.focus();
  };

  const onChange = (
    e: ChangeEvent<HTMLTextAreaElement>
  ): ChangeEventHandler<HTMLTextAreaElement> | undefined => {
    return;
  };
  return (
    <div className={style.inputMessage}>
      <textarea
        ref={inputRef}
        rows={1}
        placeholder="Massage"
        onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            send();
          }
        }}
        onChange={onChange}
        maxLength={255}
        className={style.input}
      ></textarea>
      <div onClick={() => send()} className={style.button}>
        <SendIcon />
      </div>
    </div>
  );
};

export default InputMessage;
