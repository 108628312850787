import React, { ReactNode, MouseEvent } from "react";
import style from "./style.module.css";
interface IPropsTopBar {
  children?: ReactNode;
  className?: string;
}
interface IPropsChild {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const TopBar = (props: IPropsTopBar) => {
  return (
    <div className={`${style.topBar} ${props.className}`}>{props.children}</div>
  );
};

export default TopBar;

export const TopBarTitle = (props: IPropsChild) => {
  return (
    <div className={`${style.title} ${props.className}`}>{props.children}</div>
  );
};

export const TopBarButton = (props: IPropsChild) => {
  return (
    <div
      onClick={() => {
        props.onClick!();
      }}
      className={`${style.button} ${props.className}`}
    >
      {props.children}
    </div>
  );
};
