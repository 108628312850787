import React, { useState, useEffect, ReactNode, useContext } from "react";
import style from "./style.module.css";
import profileIcon from "./../../assets/images/profileIcon.png";
import NavLayout from "../../layouts/nav";
import SearchIcon from "@mui/icons-material/Search";
import MLoading from "../../component/loading";
import Users from "../../api/Users";
import calculateAge from "../../helpers/calculateAge";
import IUser from "../../interfaces/IUser";
import Alert from "../../component/auth/popups/alert";
import { useSelector } from "react-redux";
import HomePageContext from "../../contexts/HomePageContext";
import DisplayUser from "../../component/community/display-user";
import SearchComponent from "../../component/search";
import { RootState } from "../../redux/store";
import isEmpty from "../../helpers/isEmpty";

const Community = () => {
  const [users, setUsers] = useState<IUser[] | null>(null);
  const [userSelected, setUserSelected] = useState<IUser | null>(null);
  const [serachBox, setSearchBox] = useState<boolean>(false);
  const hpContext = useContext(HomePageContext);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    // setLoading(true);
    Users.getAll().then((users) => {
      if (users) {
        setUsers(users);
      }
    });
    //   .finally(() => setLoading(false));
    // return () => {
    //   setLoading(false);
    // };
  };

  const formatBio = (bio: string): string => {
    const max = 40;
    if (bio.length > max) {
      bio = bio.substring(0, max) + "...";
    }
    return bio;
  };

  const activeMessage = (): ReactNode => {
    const active =
      user.info &&
      !isEmpty(user.info.motherLanguage ?? {}) &&
      !isEmpty(user.info.learningLanguage ?? {});

    return active ? null : (
      <Alert
        title="Important!"
        body="Hi dear! You have to fill in some information in your profile, Press OK to continue."
        onCancel={() => {
          hpContext?.setContent("profile");
        }}
      />
    );
  };

  const onUserSelected = (user: IUser) => {
    setUserSelected(user);
  };

  const displayUser = (): ReactNode => {
    if (userSelected === null) return null;
    return (
      <DisplayUser user={userSelected} onCancel={() => setUserSelected(null)} />
    );
  };

  const onSearch = async (value: string) => {
    const data = await Users.searchByFullName(value);
    setUsers(data);
  };

  const makeListLi = (list: IUser[]) => {
    if (!list.length) {
      return <div className={style.noUsers}>no users</div>;
    }

    list = list.filter((u: IUser) => u._id !== user.info?._id);
    return list.map((user: IUser) => {
      return (
        <li
          key={user._id}
          className={style.item}
          onClick={() => {
            onUserSelected(user);
          }}
        >
          <div className={style.imageContainer}>
            <img className={style.image} src={profileIcon} />
            <div className={style.onlign}></div>
          </div>
          <div className={style.info}>
            <div className={style.nac}>
              <div className={style.fullName}>{user.fullName}</div>
              <div className={style.age}>
                {user.dateBirth && calculateAge(user.dateBirth)}
              </div>
              {user.country && (
                <div className={style.country}>{user.country.code}</div>
              )}
            </div>
            <div className={style.bio}>{user.bio && formatBio(user.bio)}</div>
            <div className={style.languages}>
              <div className={style.speaks}>
                <div className={style.title}>speaks</div>
                <div className={style.content}>
                  {user.motherLanguage && user.motherLanguage.name}
                </div>
              </div>
              <div className={style.learns}>
                <div className={style.title}>learns</div>
                <div className={style.content}>
                  {user.learningLanguage && user.learningLanguage.name}
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    });
  };

  return (
    <div className={style.container}>
      {serachBox ? (
        <SearchComponent
          title="search"
          onCancel={() => {
            setSearchBox(false);
            getAllUsers();
          }}
          onSearch={onSearch}
        />
      ) : (
        <NavLayout className={style.nav}>
          <div className={style.title}>community</div>
          <div
            className={style.searchIcon}
            onClick={() => {
              setSearchBox(true);
            }}
          >
            <SearchIcon />
          </div>
        </NavLayout>
      )}

      <ul className={style.list}>{users !== null ? makeListLi(users) : ""}</ul>
      {activeMessage()}
      {displayUser()}
      {user.isLoading && <MLoading />}
    </div>
  );
};

export default Community;
