import React, {
  MouseEventHandler,
  MouseEvent,
  FormEvent,
  FormEventHandler,
  useRef,
  useState,
} from "react";
import style from "./style.module.css";
import PopupLayout from "../../../../layouts/popup";
import { patterns } from "../../../../helpers/validation";
import log from "../../../../helpers/log";

interface IProps {
  onSubmit?: (fullName?: string) => void;
  onCancel?: () => void;
  fullName?: string;
}
const pattern = patterns.fullName;
const error_message = "full name should have chars count between 3 and 20!";
const UpdateNameDialog = (props: IProps) => {
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const onSubmit = (
    e: FormEvent<HTMLFormElement>
  ): FormEventHandler<HTMLFormElement> | undefined => {
    e.preventDefault();

    if (!props.onSubmit || !inputRef.current) {
      return;
    }
    const value = inputRef.current.value;

    if (!new RegExp(pattern).test(value)) {
      setError(true);
      return;
    }
    props.onSubmit(value);

    return;
  };
  return (
    <PopupLayout onCancel={props.onCancel}>
      <form className={style.container} onSubmit={onSubmit}>
        <div className={style.title}>change full name</div>
        <input
          ref={inputRef}
          className={style.input}
          placeholder="Enter full name..."
          type="text"
          // pattern={pattern}
        />
        <p className={style.error}>{error && error_message}</p>
        <div className={style.buttons}>
          <button
            type="button"
            className={style.no}
            onClick={() => {
              props.onCancel!();
            }}
          >
            cancel
          </button>
          <button className={style.yes}>update</button>
        </div>
      </form>
    </PopupLayout>
  );
};

export default UpdateNameDialog;
