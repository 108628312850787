import React, { CSSProperties, ReactNode } from "react";
import style from "./style.module.css"
interface IProps {
  customStyle?: CSSProperties;
  children:ReactNode;
}

const MParagraph = ({ customStyle , children}: IProps) => {
  return <p className={style.para}>{children}</p>;
};

export default MParagraph;
