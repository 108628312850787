import React, { FormEvent, FormEventHandler, useRef, useState } from "react";
import style from "./style.module.css";
import PopupLayout from "../../../../layouts/popup";
import log from "../../../../helpers/log";

interface IProps {
  onSubmit?: (fullName?: string) => void;
  onCancel?: () => void;
}

const error_message = "max charachter length length 255";
const UpdateGenderDialog = (props: IProps) => {
  const [error, setError] = useState(false);
  const maleRef = useRef<HTMLInputElement>(null);
  const femaleRef = useRef<HTMLInputElement>(null);
  const onSubmit = (
    e: FormEvent<HTMLFormElement>
  ): FormEventHandler<HTMLFormElement> | undefined => {
    e.preventDefault();

    if (!props.onSubmit || !maleRef.current || !femaleRef.current) {
      return;
    }
    const value = maleRef.current.checked
      ? "male"
      : femaleRef.current.checked
      ? "female"
      : undefined;
    // code
    props.onSubmit(value);

    return;
  };
  return (
    <PopupLayout onCancel={props.onCancel}>
      <form className={style.container} onSubmit={onSubmit}>
        <div className={style.title}>change gender</div>
        <div className={style.radios}>
          <div className={style.radio}>
            <input
              ref={maleRef}
              type="radio"
              name="gender"
              defaultChecked
              className={style.value}
            />
            <div className={style.label}>male</div>
          </div>
          <div className={style.radio}>
            <input
              ref={femaleRef}
              type="radio"
              name="gender"
              className={style.value}
            />
            <div className={style.label}>female</div>
          </div>
        </div>
        <div className={style.error}>{error && error_message}</div>
        <div className={style.buttons}>
          <button
            type="button"
            className={style.no}
            onClick={() => {
              props.onCancel!();
            }}
          >
            cancel
          </button>
          <button className={style.yes}>update</button>
        </div>
      </form>
    </PopupLayout>
  );
};

export default UpdateGenderDialog;
