import React, { useEffect } from "react";
import IUser from "../../../interfaces/IUser";
import PopupLayout from "../../../layouts/popup";
import calculateAge from "../../../helpers/calculateAge";
import profileIcon from "./../../../assets/images/profileIcon.png";
import style from "./style.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import { createSearchParams, useNavigate } from "react-router-dom";

interface IProps {
  user: IUser;
  onCancel: () => void;
}
const DisplayUser = ({ user, onCancel }: IProps) => {
  const navigate = useNavigate();

  const startChat = (userid: string) => {
    navigate({
      pathname: "/chat",
      search: createSearchParams({
        u: userid,
      }).toString(),
    });
  };
  return (
    <PopupLayout onCancel={onCancel}>
      <div className={style.container}>
        <div className={style.item}>
          <div className={style.header}>
            <div
              onClick={() => {
                onCancel();
              }}
            >
              <ArrowBackIcon />
            </div>
            <div>{/* <MoreVertOutlinedIcon /> */}</div>
          </div>
          <div className={style.imageContainer}>
            <img src={profileIcon} className={style.image} />
            <div className={style.onlign}></div>
          </div>
          <div className={style.fullName}>
            <div className={style.content}>{user.fullName}</div>
          </div>
          <div className={style.startChat}>
            <div className={style.icon}>
              <ChatBubbleRoundedIcon />
            </div>
            <div
              className={style.content}
              onClick={() => {
                if (!user._id) return;
                startChat(user._id);
              }}
            >
              start chat
            </div>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.title}>learning language</div>
          <div className={style.content}>{user.learningLanguage!.name}</div>
        </div>
        <div className={style.item}>
          <div className={style.title}>mother language</div>

          <div className={style.content}>{user.motherLanguage!.name}</div>
        </div>
        <div className={style.item}>
          <div className={style.title}>bio</div>
          <div className={style.bio}>{user.bio && user.bio}</div>
        </div>
        {/* <div className={style.item}>
          <div className={style.title}>gender</div>
          <div className={style.content}>{user.gender}</div>
        </div>
        <div className={style.item}>
          <div className={style.title}>age</div>
          <div className={style.content}>{calculateAge(user.dateBirth!)}</div>
        </div>
        <div className={style.item}>
          <div className={style.title}>country</div>
          <div className={style.content}>{user.country!.name}</div>
        </div> */}
        <div className={`${style.item} ${style.personalInfo}`}>
          <div>
            <div>
              <div className={style.title}>gender</div>
              <div className={style.content}>{user.gender && user.gender}</div>
            </div>
            <div>
              <div className={style.title}>age</div>
              <div className={style.content}>
                {user.dateBirth && calculateAge(user.dateBirth!)}
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className={style.title}>country</div>
            <div className={style.content}>
              {user.country && user.country!.name}
            </div>
          </div>
        </div>
      </div>
    </PopupLayout>
  );
};

export default DisplayUser;
