import React, { MouseEvent, MouseEventHandler } from "react";
import PopupLayout from "../../../../layouts/popup";
import style from "./style.module.css";

interface IProps {
  title: string;
  body: string;
  onCancel?: () => void;
}
const Alert = (props: IProps) => {
  return (
    <PopupLayout onCancel={props.onCancel}>
      <div className={style.container}>
        <div className={style.title}>{props.title}</div>
        <div className={style.body}>{props.body}</div>
        <div className={style.button} onClick={props.onCancel}>
          Ok
        </div>
      </div>
    </PopupLayout>
  );
};

export default Alert;
