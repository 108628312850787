import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../config/axios";

export const checkUserLogin = createAsyncThunk(
  "user/checkUserLogin",
  async (_, thunkApi) => {
    try {
      const response = await axios({
        method: "post",
        url: "/auth/check",
      });
      return response.data.user;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "user/updateUserInfo",
  async (data: {}, thunkApi) => {
    // console.log(data);

    try {
      const result = await axios({
        method: "post",
        url: "/user/update-info",
        data: data,
      });
      return result.data.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
