import axios from "../config/axios";
import log from "../helpers/log";

interface IUserRegister {
  fullName: string;
  username: string;
  password: string;
}
interface IUserLogin {
  username: string;
  password: string;
}

export default class AuthService {
  /**
   * register
   */
  public static async register(user: IUserRegister) {
    try {
      const response = await axios({
        method: "post",
        url: "/auth/register",
        data: user,
      });
      log(response);

      return response.data;
    } catch (error: any) {
      log("error", error);
      return error.response?.data;
    }
  }

  /**
   * login
   */
  public static async login(user: IUserLogin) {
    try {
      const response = await axios({
        method: "post",
        url: "/auth/login",
        data: user,
      });
      log(response);
      return response.data;
    } catch (error: any) {
      log("error", error);
      return error.response?.data;
    }
  }

  /**
   * static async logout
   */
  public static async logout() {
    try {
      const response = await axios({
        method: "post",
        url: "/auth/logout",
      });
      log(response);
      return response.data.success;
    } catch (error: any) {
      log("error", error);
      return error.response.data.success;
    }
  }
  /**
   * static async check
   */
  public static async check() {
    try {
      const response = await axios({
        method: "post",
        url: "/auth/check",
      });
      // log(response);
      return response.data;
    } catch (error: any) {
      log("error", error);
      return false;
    }
  }
  public static async getToken() {
    try {
      const response = await axios({
        method: "get",
        url: "/auth/token",
      });
      // log(response);
      return response.data.auth_token;
    } catch (error: any) {
      log("error", error);
      return false;
    }
  }
}
