import React from "react";
import { homeContentType } from "../interfaces/types";

export interface IHomePageContext {
  content: homeContentType;
  setContent: (page: homeContentType) => void;
}

const HomePageContext = React.createContext<IHomePageContext | null>(null);

export default HomePageContext;
