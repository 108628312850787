import { createSlice } from "@reduxjs/toolkit";
import IUser from "../../interfaces/IUser";
import { checkUserLogin, updateUserInfo } from "./actions";

interface IInitialState {
  info: IUser | null;
  isLogin: boolean | null;
  isLoading: boolean | null;
}

const initialState: IInitialState = {
  info: null,
  isLoading: false,
  isLogin: false,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkUserLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkUserLogin.fulfilled, (state, action) => {
      state.isLogin = true;
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addCase(checkUserLogin.rejected, (state, action) => {
      // console.log(action);
      state.isLogin = false;
      state.isLoading = false;
    });

    // update info
    builder.addCase(updateUserInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      const data = action.payload;
      const key = Object.keys(data)[0];
      // console.log(key);
      state.info = { ...state.info, [key]: data[key] };

      // state.info = action.payload;
    });
    builder.addCase(updateUserInfo.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
    });
  },
});

export default user.reducer;
export const {} = user.actions;
