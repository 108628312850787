import React, { useState, useContext } from "react";
import style from "./style.module.css";
import PeopleIcon from "@mui/icons-material/People";
import ForumIcon from "@mui/icons-material/Forum";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomePageContext from "../../../contexts/HomePageContext";
import { homeContentType } from "../../../interfaces/types";

interface IProps {
  onNavigate?: (page: number) => void;
}
const HomeNav = () => {
  const hpContext = useContext(HomePageContext);

  const items = [
    {
      id: 1,
      title: "messages",
      icon: <ForumIcon />,
    },
    {
      id: 2,
      title: "community",
      icon: <PeopleIcon />,
    },
    {
      id: 3,
      title: "profile",
      icon: <AccountCircleIcon />,
    },
  ];

  return (
    <div className={style.container}>
      {items.map((item) => {
        const isselected =
          item.title === hpContext?.content ? style.selected : style.item;
        return (
          <div
            key={item.id}
            className={isselected}
            onClick={() => {
              hpContext?.setContent(item.title as homeContentType);
            }}
          >
            <div className={style.icon}>{item.icon}</div>
            <div className={style.title}>{item.title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default HomeNav;
