import React, { FormEvent, FormEventHandler, useRef, useState } from "react";
import style from "./style.module.css";
import PopupLayout from "../../../../layouts/popup";
import log from "../../../../helpers/log";

interface IProps {
  onSubmit?: (fullName?: string) => void;
  onCancel?: () => void;
  fullName?: string;
}

const UpdateAgeDialog = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onSubmit = (
    e: FormEvent<HTMLFormElement>
  ): FormEventHandler<HTMLFormElement> | undefined => {
    e.preventDefault();

    if (!props.onSubmit || !inputRef.current) {
      return;
    }
    const value = inputRef.current.value;

    log(value);
    props.onSubmit(value);

    return;
  };
  return (
    <PopupLayout onCancel={props.onCancel}>
      <form className={style.container} onSubmit={onSubmit}>
        <div className={style.title}>change date of birth</div>
        <input
          ref={inputRef}
          className={style.input}
          type="date"
          min={"1965-01-01"}
          max={`${new Date().getFullYear() - 18}-01-01`}
        />
        <div className={style.buttons}>
          <button
            type="button"
            className={style.no}
            onClick={() => {
              props.onCancel!();
            }}
          >
            cancel
          </button>
          <button className={style.yes}>update</button>
        </div>
      </form>
    </PopupLayout>
  );
};

export default UpdateAgeDialog;
