import React, { CSSProperties, ReactNode, MouseEventHandler } from "react";
import style from "./style.module.css";

interface IProps {
  children?: ReactNode;
  customStyle?: CSSProperties;
  onclick: MouseEventHandler<HTMLButtonElement>;
}
const MButton = ({ children, customStyle, onclick }: IProps) => {
  return (
    <button className={style.button} style={customStyle} onClick={onclick}>
      {children}
    </button>
  );
};

export default MButton;
