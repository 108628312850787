import React from "react";
import style from "./style.module.css";

const MLoading = () => {
  return (
    <div className={style.container}>
      <div className={style.square}>
        <div className={style.title}>please wait...</div>
        <div className={style.loading}></div>
      </div>
    </div>
  );
};

export default MLoading;
