import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import MessageService from "../../api/MessageService";
import MLoading from "../../component/loading";
import getTime from "../../helpers/getTime";
import NavLayout from "../../layouts/nav";
// import SearchIcon from "@mui/icons-material/Search";
import profileIcon from "./../../assets/images/profileIcon.png";

import style from "./style.module.css";

const Messages = () => {
    const [conversations, setConversations] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        MessageService.getConversations()
            .then((res) => {
                setConversations(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const startChat = (userid: string) => {
        navigate({
            pathname: "/chat",
            search: createSearchParams({
                u: userid,
            }).toString(),
        });
    };

    return (
        <div className={style.container}>
            <NavLayout className={style.nav}>
                <div className={style.title}>messages</div>
                <div className={style.searchIcon}>{/* <SearchIcon /> */}</div>
            </NavLayout>

            <ul className={style.list}>
                {conversations ? (
                    conversations.map((c: any) => {
                        return (
                            <li
                                onClick={() => {
                                    startChat(c.user._id);
                                }}
                                key={c.user._id}
                                className={style.item}>
                                <div className={style.imageContainer}>
                                    <img
                                        className={style.image}
                                        src={profileIcon}
                                    />
                                    {/* <div className={style.onlin}></div> */}
                                </div>
                                <div className={style.info}>
                                    <div className={style.nm}>
                                        <div className={style.fullName}>
                                            {c.user.fullName}
                                        </div>
                                        <div className={style.lastMessage}>
                                            {c.message.body}
                                        </div>
                                    </div>
                                    <div className={style.nm}>
                                        <div className={style.time}>
                                            {getTime(c.message.createdAt)}
                                        </div>
                                        {/* <div className={style.unreadMessage}>2</div> */}
                                    </div>
                                </div>
                            </li>
                        );
                    })
                ) : (
                    <div>no conversations to display</div>
                )}
            </ul>
            {loading && <MLoading />}
        </div>
    );
};

export default Messages;
