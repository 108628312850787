import { AxiosError } from "axios";
import axios from "../config/axios";
export interface IMessage {
  _id: string;
  sender: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  conversation: string;
}
const getMessages = async (receiver: string) => {
  try {
    const res = await axios({
      method: "get",
      url: "message/get-messages",
      params: {
        receiver,
        skip: 0,
      },
    });
    if (res.data && res.data.success && res.data.messages.length) {
      return res.data.messages;
    }
    return false;
  } catch (error) {
    return false;
  }
};
const getConversations = async () => {
  try {
    const res = await axios({
      method: "get",
      url: "message/get-conversations",
    });
    if (res.data && res.data.success && res.data.conversations.length) {
      return res.data.conversations;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const sendMessage = async (body: string, receiver: string) => {
  try {
    const response = await axios({
      method: "post",
      url: "/message/send",
      data: {
        body,
        receiver,
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

const MessageService = {
  sendMessage,
  getMessages,
  getConversations,
};
export default MessageService;
