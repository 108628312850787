import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MLoading from "../../component/loading";
import HomeNav from "../../component/nav/home-nav";
import HomePageContext from "../../contexts/HomePageContext";
import { homeContentType } from "../../interfaces/types";
import { AppDispatch, RootState } from "../../redux/store";
import { checkUserLogin } from "../../redux/user/actions";
import Community from "../community";
import Messages from "../messages";
import Profile from "../profile";
import style from "./style.module.css";

const Home = () => {
  const [content, setContent] = useState<homeContentType>(
    (window.sessionStorage.getItem("home-content-page") as homeContentType) ??
      "community"
  );
  const getSubPage = () => {
    return content === "messages" ? (
      <Messages />
    ) : content === "community" ? (
      <Community />
    ) : (
      <Profile />
    );
  };

  useEffect(() => {
    window.sessionStorage.setItem("home-content-page", content);
  }, [content]);

  return (
    <HomePageContext.Provider value={{ content, setContent }}>
      <div className={style.container}>
        <div className={style.content}>{getSubPage()}</div>
        <div className={style.nav}>
          <HomeNav />
        </div>
      </div>
    </HomePageContext.Provider>
  );
};

export default Home;
