import axios from "../../config/axios";
import log from "../../helpers/log";

async function get(url: string) {
  try {
    const result = await axios(url);
    return result.data;
  } catch (error: any) {
    log(error);
    return error.response?.data;
  }
}

export const getLanguages = async () => {
  let l = await get("/other/languages");

  let i = 1;
  const result = l.map((e: any) => {
    return {
      id: i++,
      name: e.name,
      code: e.code,
    };
  });
  return result;
};
export const getCountries = async () => {
  const c = await get("/other/countries");

  let i = 1;
  const result = c.map((e: any) => {
    return {
      id: i++,
      name: e.name,
      code: e.code,
    };
  });
  return result;
};
