import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    useNavigate,
} from "react-router-dom";
import Chat from "../pages/chat";
import Home from "../pages/home";
import Login from "../pages/login";
import Register from "../pages/register";
import TestPage from "../pages/test";
import { RootState } from "../redux/store";

const MainRouter = () => {
    const user = useSelector((state: RootState) => state.user);

    // console.log(user);
    if (user.isLogin === null || user.isLoading === null) {
        console.log(user);
        return null;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={
                        <Protected
                            condition={user.isLogin === true}
                            to="/login"
                        />
                    }>
                    <Route path="/" element={<Home />} />
                    <Route path="/chat" element={<Chat />} />
                </Route>
                <Route
                    element={
                        <Protected condition={user.isLogin === false} to="/" />
                    }>
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                </Route>

                <Route path="/test" element={<TestPage />} />
                <Route path="/*" element={<div>not found</div>} />
            </Routes>
        </BrowserRouter>
    );
};

export default MainRouter;

const Protected = (props: {
    children?: ReactNode;
    condition: boolean;
    to: string;
}) => {
    const navigate = useNavigate();
    if (!props.condition) {
        navigate(props.to, { replace: true });
        return null;
    }
    return <Outlet />;
};
