import axios from "../config/axios";
import calculateAge from "../helpers/calculateAge";
import isEmpty from "../helpers/isEmpty";
import { patterns } from "../helpers/validation";
import AuthService from "./AuthService";

class CurrentUser {
  public static _id: string = "";
  public static auth_token: string = "";
  public static active: boolean = false;
  public static username: string = "";
  public static fullName: string = "";
  public static gender: string = "";
  public static bio: string = "";
  public static age: string | number = "";
  public static country: {
    name: string;
    code: string;
  } = {
    name: "",
    code: "",
  };
  public static learningLanguage: {
    name: string;
    code: string;
  } = {
    name: "",
    code: "",
  };
  public static motherLanguage: {
    name: string;
    code: string;
  } = {
    name: "",
    code: "",
  };

  public static async check(): Promise<boolean> {
    const result = await AuthService.check();

    // console.log(result.user);
    if (result && result.success) {
      CurrentUser.auth_token = result.user.auth_token;
      if (result.user.username) CurrentUser.username = result.user.username;
      if (result.user.fullName) CurrentUser.fullName = result.user.fullName;
      if (result.user._id) CurrentUser._id = result.user._id;
      if (result.user.bio) CurrentUser.bio = result.user.bio;
      if (result.user.gender) CurrentUser.gender = result.user.gender;
      if (!isEmpty(result.user.motherLanguage))
        CurrentUser.motherLanguage = result.user.motherLanguage;
      if (!isEmpty(result.user.learningLanguage))
        CurrentUser.learningLanguage = result.user.learningLanguage;
      if (!isEmpty(result.user.country))
        CurrentUser.country = result.user.country;
      if (result.user.dateBirth) {
        CurrentUser.age = calculateAge(result.user.dateBirth);
      }

      CurrentUser.active =
        !isEmpty(result.user.learningLanguage) &&
        !isEmpty(result.user.motherLanguage);
    }
    return result.success;
  }

  private static async updateInfo(data: object) {
    try {
      const result = await axios({
        method: "post",
        url: "/user/update-info",
        data: data,
      });
      return result.data.success;
    } catch (error: any) {
      return error.response.data.success;
    }
  }
  /**
   * setFullName
   */
  public static async setFullName(fullName: string) {
    if (!new RegExp(patterns.fullName).test(fullName)) {
      return false;
    }
    const success = await CurrentUser.updateInfo({ fullName });
    if (!success) {
      return false;
    }
    CurrentUser.fullName = fullName;
    return true;
  }
  /**
   * setCountry
   */
  public static async setCountry(country: { name: string; code: string }) {
    if (isEmpty(country)) {
      return false;
    }
    const success = await CurrentUser.updateInfo({ country });
    if (success) CurrentUser.country = country;
    return success;
  }
  public static async setLearningLanguage(learningLanguage: {
    name: string;
    code: string;
  }) {
    if (isEmpty(learningLanguage)) {
      return false;
    }
    const success = await CurrentUser.updateInfo({ learningLanguage });
    if (success) CurrentUser.learningLanguage = learningLanguage;
    return success;
  }
  public static async setMotherLanguage(motherLanguage: {
    name: string;
    code: string;
  }) {
    if (isEmpty(motherLanguage)) {
      return false;
    }
    const success = await CurrentUser.updateInfo({ motherLanguage });
    if (success) CurrentUser.motherLanguage = motherLanguage;
    return success;
  }
  public static async setBio(bio: string) {
    if (bio.length >= 255) {
      return false;
    }
    const success = await CurrentUser.updateInfo({ bio });
    if (success) CurrentUser.bio = bio;
    return success;
  }
  public static async setGender(gender: string) {
    if (gender !== "male" && gender !== "female") {
      return false;
    }
    const success = await CurrentUser.updateInfo({ gender });
    if (success) CurrentUser.gender = gender;
    return success;
  }
  public static async setDateBirth(dateBirth: string) {
    const success = await CurrentUser.updateInfo({ dateBirth });
    if (success) CurrentUser.age = calculateAge(dateBirth);
    return success;
  }
}

export default CurrentUser;
