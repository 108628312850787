import React, { useEffect, useRef, useState } from "react";
import TopBar, { TopBarButton, TopBarTitle } from "../../component/top-bar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { io, Socket } from "socket.io-client";
import style from "./style.module.css";
import ListMessages, {
  MessageItem,
  MessageText,
  MessageTime,
} from "../../component/chat/list-messages";
import InputMessage from "../../component/chat/input-message";
import MessageService, { IMessage } from "../../api/MessageService";
import { useSearchParams } from "react-router-dom";
import Users from "../../api/Users";
import MLoading from "../../component/loading";
import getTime from "../../helpers/getTime";
import { SERVER } from "../../constants/urls";
import SocketMessage from "../../socket/SocketMessage";
import IUser from "../../interfaces/IUser";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Chat = () => {
  const lastItemRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const [messages, setMessages] = useState<IMessage[] | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [socketMessage, setSocketMessage] = useState<SocketMessage | undefined>(
    undefined
  );
  const currentUserInfo = useSelector((state: RootState) => state.user).info;

  const scrollDown = (first: boolean = false) => {
    if (!lastItemRef.current) return;
    if (first) return lastItemRef.current.scrollIntoView();
    /** check scroll height */
    const list = lastItemRef.current.parentElement;
    if (list) {
      const { scrollHeight, scrollTop, offsetHeight } = list;
      const scrollBottom = Math.floor(scrollTop + offsetHeight);
      if (scrollHeight - scrollBottom > 100) return;
    }

    lastItemRef.current.scrollIntoView();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const receiver = searchParams.get("u");
      if (!receiver) return window.location.replace("/");
      // check user is real
      const user = await Users.checkUserIfExists(receiver);

      if (!user) {
        return window.location.replace("/");
      }
      setUser(user);

      // load messages if exists
      const messages = await MessageService.getMessages(receiver);

      if (messages) {
        setMessages(messages);
      }
      scrollDown(true);
      setLoading(false);
    })();

    /**connect to the socket server */
    setSocketMessage(new SocketMessage(currentUserInfo?.auth_token ?? ""));

    /** on window height change */
    document.body.onresize = () => {
      scrollDown(true);
    };
  }, []);

  useEffect(() => {
    if (!socketMessage) return;

    socketMessage.onMessageReceived((message: IMessage) => {
      setMessages((old) => {
        if (old) {
          return [...old, message];
        } else {
          return [message];
        }
      });
    });

    return () => {
      if (socketMessage) socketMessage.io.close();
    };
  }, [socketMessage]);

  useEffect(() => {
    scrollDown();
  }, [messages]);

  const onSend = async (message: string) => {
    const receiver = searchParams.get("u");
    if (!receiver) return alert("no conversation or userid =>receiver");
    const data = await MessageService.sendMessage(message, receiver);
    /**append message */
  };

  return (
    <div className={style.container}>
      <TopBar>
        <TopBarButton
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowBackIcon />
        </TopBarButton>
        <TopBarTitle>{user?.fullName}</TopBarTitle>
        <TopBarButton>
          <MoreVertOutlinedIcon />
        </TopBarButton>
      </TopBar>

      {/* messages list */}
      <ListMessages className={style.listMessages}>
        {messages
          ? messages.map((m) => {
              return (
                <MessageItem
                  key={m._id}
                  isMine={m.sender === currentUserInfo?._id}
                >
                  <MessageText>{m.body}</MessageText>
                  <MessageTime>{getTime(m.createdAt)}</MessageTime>
                </MessageItem>
              );
            })
          : ""}

        <div ref={lastItemRef}></div>
      </ListMessages>

      <InputMessage onSend={onSend} />
      {/* loading */}
      {loading && <MLoading />}
    </div>
  );
};

export default Chat;
