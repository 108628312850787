import React, { useState } from "react";
import style from "./style.module.css";
import profileIcon from "./../../assets/images/profileIcon.png";
// import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditIcon from "@mui/icons-material/Edit";
import UpdateNameDialog from "../../component/profile/dialogs/update-name";
import UpdateAgeDialog from "../../component/profile/dialogs/update-age";
import UpdateBioDialog from "../../component/profile/dialogs/update-bio";
import UpdateGenderDialog from "../../component/profile/dialogs/update-gender";
import UpdateCountryDialog from "../../component/profile/dialogs/update-country";
import AuthService from "../../api/AuthService";
import UpdateLearningLanguageDialog from "../../component/profile/dialogs/update-ll";
import UpdateMotherLanguageDialog from "../../component/profile/dialogs/update-ml";
import MainMenu from "../../component/profile/menus/main_manu";
import CurrentUser from "../../api/CurrentUser";
import MLoading from "../../component/loading";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../redux/user/actions";
import { AppDispatch, RootState, store } from "../../redux/store";
import calculateAge from "../../helpers/calculateAge";
// import log from "../../helpers/log";
// import UpdateAgeDialog from "../../component/profile/dialogs/update-age";

const initialDialogState = {
  fullName: false,
  motherLanguage: false,
  learningLanguage: false,
  bio: false,
  gender: false,
  age: false,
  country: false,
};
const Profile = () => {
  const [dialogs, setDialogs] = useState(initialDialogState);
  const [mainMenu, setMainMenu] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const userInfo = useSelector((state: RootState) => state.user).info;

  const dispatch = useDispatch<AppDispatch>();

  /**
   * show dialog for changing user information
   */
  type dialogsType =
    | "fullName"
    | "motherLanguage"
    | "learningLanguage"
    | "bio"
    | "gender"
    | "age"
    | "country";
  const showDialog = (dialog: dialogsType) => {
    setDialogs((d) => {
      return { ...d, [dialog]: true };
    });
  };
  const hideDialog = (dialog: dialogsType) => {
    setDialogs((d) => {
      return { ...d, [dialog]: false };
    });
  };
  /**
   * build dialog
   */
  const writeDialog = () => {
    const copyDialogs = { ...dialogs };
    let currentDialog: dialogsType | null = null;
    let d: keyof typeof copyDialogs = "fullName";
    for (d in copyDialogs) {
      if (copyDialogs[d]) {
        currentDialog = d;
        break;
      }
    }

    const dialogsComponent = [
      {
        name: "fullName",
        content: (
          <UpdateNameDialog
            onCancel={function () {
              hideDialog("fullName");
            }}
            onSubmit={(fullName) => {
              if (!fullName) return;
              hideDialog("fullName");
              dispatch(updateUserInfo({ fullName }));
            }}
          />
        ),
      },
      {
        name: "age",
        content: (
          <UpdateAgeDialog
            onCancel={function () {
              hideDialog("age");
            }}
            onSubmit={(dateBirth) => {
              if (!dateBirth) return;
              hideDialog("age");
              dispatch(updateUserInfo({ dateBirth }));
            }}
          />
        ),
      },
      {
        name: "bio",
        content: (
          <UpdateBioDialog
            onCancel={function () {
              hideDialog("bio");
            }}
            onSubmit={(bio) => {
              if (!bio) return;
              hideDialog("bio");
              dispatch(updateUserInfo({ bio }));
            }}
          />
        ),
      },
      {
        name: "gender",
        content: (
          <UpdateGenderDialog
            onCancel={function () {
              hideDialog("gender");
            }}
            onSubmit={(gender) => {
              if (!gender) return;
              hideDialog("gender");
              dispatch(updateUserInfo({ gender }));
            }}
          />
        ),
      },
      {
        name: "country",
        content: (
          <UpdateCountryDialog
            onCancel={function () {
              hideDialog("country");
            }}
            onSubmit={(country) => {
              if (!country) return;
              hideDialog("country");
              dispatch(updateUserInfo({ country }));
            }}
          />
        ),
      },
      {
        name: "learningLanguage",
        content: (
          <UpdateLearningLanguageDialog
            onCancel={function () {
              hideDialog("learningLanguage");
            }}
            onSubmit={(learningLanguage) => {
              if (!learningLanguage) return;
              hideDialog("learningLanguage");
              console.log(learningLanguage);

              dispatch(updateUserInfo({ learningLanguage }));
            }}
          />
        ),
      },
      {
        name: "motherLanguage",
        content: (
          <UpdateMotherLanguageDialog
            onCancel={function () {
              hideDialog("motherLanguage");
            }}
            onSubmit={(motherLanguage) => {
              if (!motherLanguage) return;
              hideDialog("motherLanguage");
              dispatch(updateUserInfo({ motherLanguage }));
            }}
          />
        ),
      },
    ];

    for (let d of dialogsComponent)
      if (d.name === currentDialog) return d.content;
    return <></>;
  };

  return (
    <>
      <ul className={style.container}>
        <li className={style.item}>
          <div className={style.mainMenu} onClick={() => setMainMenu(true)}>
            <MenuIcon />
          </div>

          <div className={style.imageContainer}>
            <img src={profileIcon} className={style.image} />
            <div className={style.edit}>
              <CameraAltIcon />
            </div>
          </div>
          <div className={style.fullName}>
            <div className={style.content}>{userInfo?.fullName}</div>
            <div
              className={style.edit}
              onClick={() => {
                showDialog("fullName");
              }}
            >
              <EditIcon />
            </div>
          </div>
        </li>
        <li className={style.item}>
          <div
            className={style.title}
            style={{ color: userInfo?.learningLanguage?.name ? "" : "red" }}
          >
            learning language
          </div>
          <div
            className={style.edit}
            onClick={() => {
              showDialog("learningLanguage");
            }}
          >
            <EditIcon />
          </div>
          <ul className={style.list}>
            <li>{userInfo?.learningLanguage?.name}</li>
          </ul>
        </li>
        <li className={style.item}>
          <div
            className={style.title}
            style={{ color: userInfo?.motherLanguage?.name ? "" : "red" }}
          >
            mother language
          </div>
          <div
            className={style.edit}
            onClick={() => {
              showDialog("motherLanguage");
            }}
          >
            <EditIcon />
          </div>
          <ul className={style.list}>
            <li>{userInfo?.motherLanguage?.name}</li>
          </ul>
        </li>
        <li className={style.item}>
          <div className={style.title}>bio</div>
          <div
            className={style.edit}
            onClick={() => {
              showDialog("bio");
            }}
          >
            <EditIcon />
          </div>
          <div className={style.bio}>{userInfo?.bio}</div>
        </li>
        <li className={style.item}>
          <div className={style.title}>gender</div>
          <div
            className={style.edit}
            onClick={() => {
              showDialog("gender");
            }}
          >
            <EditIcon />
          </div>
          <div className={style.content}>{userInfo?.gender}</div>
        </li>
        <li className={style.item}>
          <div className={style.title}>age</div>
          <div
            className={style.edit}
            onClick={() => {
              showDialog("age");
            }}
          >
            <EditIcon />
          </div>
          <div className={style.content}>
            {userInfo?.dateBirth && calculateAge(userInfo.dateBirth)}
          </div>
        </li>
        <li className={style.item}>
          <div className={style.title}>country</div>
          <div
            className={style.edit}
            onClick={() => {
              showDialog("country");
            }}
          >
            <EditIcon />
          </div>
          <div className={style.content}>{userInfo?.country?.name}</div>
        </li>
      </ul>
      {/* dialogs */}
      {writeDialog()}
      {mainMenu && (
        <MainMenu
          onCancel={() => {
            setMainMenu(false);
          }}
        />
      )}
      {loading ? <MLoading /> : ""}
    </>
  );
};

export default Profile;
