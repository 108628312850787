import React, {
  useRef,
  useEffect,
  ChangeEvent,
  ChangeEventHandler,
} from "react";
import PopupLayout from "../../layouts/popup";
import style from "./style.module.css";

interface IProps {
  onCancel?: () => void;
  onSearch?: (text: string) => void;
  title?: string;
}

const SearchComponent = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  const change = (
    e: ChangeEvent<HTMLInputElement>
  ): ChangeEventHandler<HTMLInputElement> | undefined => {
    if (!buttonRef.current) return;

    if (e.target.value.trim() === "") buttonRef.current.disabled = true;
    else buttonRef.current.disabled = false;
  };
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.title}>{props.title ?? "Title"}</div>
        <button className={style.hide} onClick={props.onCancel}>
          X
        </button>
      </div>
      <div className={style.searchBox}>
        <input
          onChange={change}
          ref={inputRef}
          type="text"
          placeholder="search..."
          className={style.input}
        />
        <button
          ref={buttonRef}
          className={style.button}
          onClick={() => {
            if (!props.onSearch || !inputRef.current) return;
            const { value } = inputRef.current;
            props.onSearch(value.trim());
          }}
        >
          search
        </button>
      </div>
    </div>
  );
};

export default SearchComponent;
