import React, { FormEvent, FormEventHandler, useRef, useState } from "react";
import style from "./style.module.css";
import PopupLayout from "../../../../layouts/popup";
import log from "../../../../helpers/log";

interface IProps {
  onSubmit?: (fullName?: string) => void;
  onCancel?: () => void;
  fullName?: string;
}

const error_message = "max charachter length length 255";
const UpdateBioDialog = (props: IProps) => {
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const onSubmit = (
    e: FormEvent<HTMLFormElement>
  ): FormEventHandler<HTMLFormElement> | undefined => {
    e.preventDefault();

    if (!props.onSubmit || !inputRef.current) {
      return;
    }
    const value = inputRef.current.value;
    if (value.length > 255) {
      setError(true);
      return;
    }

    log(value.length);
    props.onSubmit(value);

    return;
  };
  return (
    <PopupLayout onCancel={props.onCancel}>
      <form className={style.container} onSubmit={onSubmit}>
        <div className={style.title}>change bio</div>
        <textarea ref={inputRef} className={style.input}></textarea>
        <div className={style.error}>{error && error_message}</div>
        <div className={style.buttons}>
          <button
            type="button"
            className={style.no}
            onClick={() => {
              props.onCancel!();
            }}
          >
            cancel
          </button>
          <button className={style.yes}>update</button>
        </div>
      </form>
    </PopupLayout>
  );
};

export default UpdateBioDialog;
