import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MLoading from "./component/loading";
import { AppDispatch, RootState } from "./redux/store";
import { checkUserLogin } from "./redux/user/actions";
import MainRouter from "./router";

function App() {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(checkUserLogin());
  }, []);

  const loading = user.isLoading === true || user.isLoading === null;
  return (
    <div className="App">
      <MainRouter />
      {/* {loading && <MLoading />} */}
    </div>
  );
}

export default App;
