import { io, Socket } from "socket.io-client";
import CurrentUser from "../api/CurrentUser";
import { IMessage } from "../api/MessageService";
import { SERVER } from "../constants/urls";

export default class SocketMessage {
  public static instance: SocketMessage;
  public io: Socket;

  constructor(token: string) {
    this.io = io(SERVER + "?auth_token=" + token);
    SocketMessage.instance = this;
    this.io.on("connect", this.onConnect);
    this.io.on("disconnect", this.onDisconnect);
    // this.io.on("reconnect_attempt", this.onDisconnect);
    // this.io.on("reconnect", this.onDisconnect);
    this.io.on("connect_error", () => {
      console.log("socket error");
    });
  }
  private onConnect() {
    console.log("socket connected");
  }
  private onDisconnect() {
    console.log("socket diconnected");
  }

  public sendMessage = (message: { receiver: string; body: string }) => {
    this.io.emit("message", message);
  };

  public onMessageReceived(onMessage: (message: IMessage) => void): void {
    this.io.on("message", onMessage);
  }
}
