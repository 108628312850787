import React, { ReactNode, FormEvent } from "react";
import style from "./style.module.css";

interface IProps {
  children: ReactNode;
}

const AuthLayout = (props: IProps) => {
  return (
    <form
      autoComplete="false"
      autoCorrect="false"
      defaultValue={""}
      onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}
      className={style.container}
    >
      {props.children}
    </form>
  );
};

export default AuthLayout;
