import React, { CSSProperties, ReactNode } from "react";
import style from "./style.module.css";

const Title = ({
  children,
  customStyle,
}: {
  children: ReactNode;
  customStyle?: CSSProperties;
}) => {
  return (
    <div style={customStyle} className={style.title}>
      {children}
    </div>
  );
};

export default Title;
