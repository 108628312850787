import React, { useState, CSSProperties, useEffect } from "react";
import isEmpty from "../../../helpers/isEmpty";
import style from "./style.module.css";

interface IItem {
  id: number;
  name: string;
  code: string;
}
interface IProps {
  data: IItem[];
  default?: string;
  className?: CSSProperties;
  onSelect?: (elemet: any) => void;
}

const MSelect = (props: IProps) => {
  const [itemsSearch, setItemsSearch] = useState<IItem[] | null>(null);
  const [selected, setSelected] = useState<IItem | null>(null);

  useEffect(() => {
    /**
     * detect default
     */

    if (props.default) {
      const item = props.data.find((d) => d.name === props.default);
      console.log("set default", item);
      if (item) {
        onSelect(item);
      }
    }
  }, []);

  const search = (name: string) => {
    if (name === "") {
      setItemsSearch(null);
      return;
    }

    const newItems = props.data.filter((d) =>
      d.name.toLowerCase().startsWith(name.toLowerCase())
    );
    setItemsSearch(newItems);
  };

  const onSelect = (item: IItem) => {
    setSelected(item);
    props.onSelect!(item);
  };

  const makeListLi = (data: IItem[]) => {
    return data.map((i) => (
      <li
        key={i.id}
        className={`${i.id === selected?.id ? style.selected : style.item}`}
        onClick={() => {
          onSelect(i);
        }}
      >
        {i.name}
      </li>
    ));
  };
  return (
    <div className={style.container + " " + props.className}>
      <input
        className={style.search}
        onChange={(e) => {
          search(e.target.value);
        }}
        placeholder="search..."
        type="text"
      />

      <ul className={style.list}>
        {makeListLi(itemsSearch ? itemsSearch : props.data)}
      </ul>
    </div>
  );
};

export default MSelect;
