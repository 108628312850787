function calculateAge(date: string) {
  const currentDate = new Date().getTime();
  let userDateArr = date.toString().split("-");
  let userDate = new Date(
    +userDateArr[0],
    +userDateArr[1] - 1,
    +userDateArr[2]
  );

  const age = Math.floor(
    (currentDate - userDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25)
  );
  return age;
}
export default calculateAge;
