import React, {
  CSSProperties,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useRef,
} from "react";
import style from "./style.module.css";

interface IProps {
  children: ReactNode;
  customStyle?: CSSProperties;
  className?: string;
  onCancel?: () => void;
}

const PopupLayout = (props: IProps) => {
  const containerRef = useRef(null);
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === containerRef.current) {
      props.onCancel && props.onCancel();
    }
  };
  return (
    <div
      ref={containerRef}
      className={`${style.container} ${props.className}`}
      onClick={onClick}
    >
      {/* <div className={style.content}>{props.children}</div> */}
      {props.children}
    </div>
  );
};

export default PopupLayout;
