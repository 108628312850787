import React, { ReactNode } from "react";
import style from "./style.module.css";

interface IProps {
  children?: ReactNode;
  className?: string;
}

const ListMessages = (props: IProps) => {
  return (
    <ul className={`${style.listMessages} ${props.className}`}>
      {props.children}
    </ul>
  );
};

export default ListMessages;

export const MessageText = (props: IProps) => {
  return (
    <div className={`${style.messageText} ${props.className}`}>
      {props.children}
    </div>
  );
};
export const MessageTime = (props: IProps) => {
  return (
    <div className={`${style.messageTime} ${props.className}`}>
      {props.children}
    </div>
  );
};

interface IPropsItem {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  isMine: boolean;
}

export const MessageItem = (props: IPropsItem) => {
  const className = `${style.messageItem} ${props.isMine && style.mine}`;

  return (
    <li className={`${className} ${props.className}`}>{props.children}</li>
  );
};
