import React, { CSSProperties } from "react";
import SearchComponent from "../../component/search";

const TestPage = () => {
  const style: CSSProperties = {
    height: "100%",
    position: "fixed",
    top: "0px",
    bottom: "0px",
    right: "0px",
    left: "0px",
  };

  return (
    <div style={style}>
      <SearchComponent />
    </div>
  );
};

export default TestPage;
