import React, { ReactNode } from "react";
import style from "./style.module.css";

const NavLayout = (props: { children: ReactNode; className?: string }) => {
  return (
    <div className={`${style.container} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default NavLayout;
