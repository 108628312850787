import React from "react";
import AuthService from "../../../../api/AuthService";
import PopupLayout from "../../../../layouts/popup";
import style from "./style.module.css";

const MainMenu = (props: { onCancel?: () => void }) => {
  return (
    <PopupLayout onCancel={props.onCancel}>
      <div className={style.container}>
        <div className={style.menu}>
          <div className={style.header}>
            <div className={style.close} onClick={() => props.onCancel!()}>
              X
            </div>
            <div className={style.title}>Menu</div>
            <div className={`${style.close} ${style.hidden}`}>X</div>
          </div>
          <div className={style.item}>
            <div
              className={style.logout}
              onClick={async () => {
                if (await AuthService.logout()) {
                  window.location.reload();
                } else {
                  // error
                }
              }}
            >
              logout
            </div>
          </div>
        </div>
      </div>
    </PopupLayout>
  );
};

export default MainMenu;
