import axios from "../config/axios";
import IUser from "../interfaces/IUser";
import CurrentUser from "./CurrentUser";

export default class Users {
  /**
   * get
   */
  public static async getAll() {
    const result = await get();

    if (result.success) {
      return result.users;
    }
    return [];
  }
  public static async searchByFullName(fullName: string) {
    const result = await get("/users/search-by-fullname", { fullName });

    if (result.success) {
      return result.users;
    }
    return [];
  }
  public static async checkUserIfExists(userid: string) {
    const res = await get("/users/check-user-if-exists", { _id: userid });
    return res ? res.user : false;
  }
}

async function get(url: string = "/users", params: IUser = {}) {
  try {
    const response = await axios({
      url,
      params,
    });
    return response.data;
  } catch (error: any) {
    return false;
  }
}
