import React, {
  FormEvent,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import style from "./../styles/mlang_llang_country.module.css";
import PopupLayout from "../../../../layouts/popup";
import log from "../../../../helpers/log";
import MSelect from "../../../auth/select";
import { getLanguages } from "../../../../api/other-services";

/**
 * interface learning language
 */
interface ILL {
  name: string;
  code: string;
  id?: number;
}

interface IProps {
  onSubmit?: (country?: ILL) => void;
  onCancel?: () => void;
}

const error_message = "please chose a country !";
const UpdateLearningLanguageDialog = (props: IProps) => {
  const [error, setError] = useState(false);
  const [countries, setCountries] = useState<any>(null);
  const [value, setValue] = useState<ILL | null>(null);

  useEffect(() => {
    (async function () {
      const result = await getLanguages();
      // console.log(result);

      setCountries(result);
    })();
  }, []);

  if (!countries) {
    return (
      <PopupLayout onCancel={props.onCancel}>
        <div style={{ backgroundColor: "white" }}>Loading...</div>
      </PopupLayout>
    );
  }

  const onSubmit = (
    e: FormEvent<HTMLFormElement>
  ): FormEventHandler<HTMLFormElement> | undefined => {
    e.preventDefault();

    if (!props.onSubmit) {
      return;
    }

    if (!value) {
      setError(true);
      return;
    }

    props.onSubmit(value);

    return;
  };

  const onSelect = function (ele: ILL) {
    setValue(ele);
  };
  return (
    <PopupLayout onCancel={props.onCancel}>
      <form className={style.container} onSubmit={onSubmit}>
        <div className={style.title}>change learning language</div>

        <MSelect className={style.list} data={countries} onSelect={onSelect} />

        <div className={style.error}>{error && error_message}</div>

        <div className={style.buttons}>
          <button
            type="button"
            className={style.no}
            onClick={() => {
              props.onCancel!();
            }}
          >
            cancel
          </button>
          <button className={style.yes}>update</button>
        </div>
      </form>
    </PopupLayout>
  );
};

export default UpdateLearningLanguageDialog;
