import React, {
  useState,
  MouseEvent,
  FocusEvent,
  FocusEventHandler,
  ChangeEvent,
  ChangeEventHandler,
} from "react";
import AuthService from "../../api/AuthService";
import MButton from "../../component/auth/button";
import FormInput from "../../component/auth/input/FormInput";
import { IFormInputProps } from "../../component/auth/input/interfaces";
import MParagraph from "../../component/auth/paragraph";
import Alert from "../../component/auth/popups/alert";
import Title from "../../component/auth/title";
import log from "../../helpers/log";
import { patterns } from "../../helpers/validation";
import FormL from "../../layouts/auth";

const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const [values, setValues] = useState(initialValues);
  const [responseAlert, setResponseAlert] = useState("");

  const displayResponseAlert = () => {
    const props = {
      title: "Info!",
      body: responseAlert,
      onCancel: () => {
        setResponseAlert("");
      },
    };

    return responseAlert && <Alert {...props}></Alert>;
  };

  const login = async () => {
    const result = await AuthService.login({
      username: values.username,
      password: values.password,
    });
    /**
     * register complated
     */

    if (result?.success) {
      // success
      // setResponseAlert("Login successfully!");
      // log(result);
      window.location.reload();
    } else {
      // failed
      setResponseAlert(result.error);
    }
  };
  const onsubmit = (e: MouseEvent<HTMLButtonElement>): void => {
    for (let p in patterns) {
      const regex = new RegExp(patterns[p as keyof typeof patterns]);
      if (!regex.test(values[p as keyof typeof values])) {
        return;
      }
    }

    login();
  };

  const onBlur = (
    e: FocusEvent<HTMLInputElement>
  ): FocusEventHandler<HTMLInputElement> | undefined => {
    return undefined;
  };
  const onChange = (
    e: ChangeEvent<HTMLInputElement>
  ): ChangeEventHandler<HTMLInputElement> | undefined => {
    const { value, name } = e.target;
    setValues((state) => {
      return {
        ...state,
        [name]: value,
      };
    });
    return undefined;
  };
  const inputs: IFormInputProps[] = [
    {
      id: 2,
      name: "username",
      label: "username",
      error:
        "username should start with [a-zA-Z], chars count between 6 and 20, and just contains [a-zA-Z0-9_]",
      type: "text",
      pattern: patterns.username,
      required: true,
      onBlur: onBlur,
      onChange: onChange,
    },
    {
      id: 3,
      name: "password",
      label: "Password",
      error: "password should length should be between 6 and 20",
      type: "password",
      pattern: patterns.password,
      required: true,
      onBlur: onBlur,
      onChange: onChange,
    },
  ];

  return (
    <>
      <FormL>
        <Title>Login</Title>
        {inputs.map((input) => {
          return <FormInput {...input} key={input.id} />;
        })}
        <MButton onclick={onsubmit}>login</MButton>
        <MParagraph>
          don't you have an account?{" "}
          <a
            onClick={() => {
              window.location.replace("/register");
            }}
          >
            register now
          </a>
        </MParagraph>
      </FormL>
      {/* response alert */}
      {displayResponseAlert()}
    </>
  );
};

export default Login;
